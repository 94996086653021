import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {MainPage} from "./components/main-page/MainPage";
import {Products} from "./components/products/Products";
import {ProductWrapper} from "./components/products/ProductWrapper";
import { ThemeProvider } from '@material-tailwind/react';

import facial_cleanser from "./assets/images/main-page/cleanser-for-site-1.jpg";
import hyalurone from "./assets/images/main-page/ha-serum-for-site-1.jpg";
import moisturiser from "./assets/images/main-page/moisturizer-for-site-1.jpg";
import cleaner_hover from "./assets/images/main-page/cleanser-for-site-2.jpg"
import hyaluronic_hover from "./assets/images/main-page/ha-serum-for-site-2.jpg"
import moisturizer_hover from "./assets/images/main-page/moisturizer-for-site-2.jpg"


function App() {
  const products = [
      {id: 1, images: [facial_cleanser, cleaner_hover], title: "Facial Cleanser", price: 25, description: "L’éternité's facial cleanser is designed to be gentle and safe for all skin types, providing a soft yet effective cleansing experience. Formulated with natural ingredients, it removes impurities without stripping the skin of its natural oils, leaving your skin feeling fresh, balanced, and hydrated."},
      {id: 2, images: [hyalurone, hyaluronic_hover], title: "Hyaluronic acid", price: 24, description: "Low molecular weight hyaluronic acid is a highly effective skincare ingredient known for its ability to penetrate deeply into the skin, delivering intense hydration and nourishment. Unlike traditional hyaluronic acid, which primarily sits on the surface, this smaller molecular form can reach the deeper layers of the skin, providing lasting moisture and promoting a plump, youthful appearance. It helps to reduce the appearance of fine lines and wrinkles by improving skin elasticity and texture. L’éternité incorporates low molecular weight hyaluronic acid in its formulations to ensure optimal skincare results, leaving your skin smooth, supple, and radiant."},
      {id: 3, images: [moisturiser, moisturizer_hover], title: "Facial Moisturizer", price: 24, description: "L’éternité's facial moisturizer is a luxurious and nourishing skincare product designed to provide long-lasting hydration and protection for your skin. Enriched with high-quality ingredients, it works to lock in moisture and improve skin elasticity, leaving your complexion soft, smooth, and radiant. The lightweight formula absorbs quickly without feeling greasy, making it suitable for all skin types, including sensitive skin. Infused with antioxidants and essential nutrients, L’éternité's facial moisturizer helps to combat environmental stressors and reduce the appearance of fine lines and wrinkles, ensuring your skin stays healthy and youthful-looking."}
  ]

  return (
      <>
          <div className="App flex flex-col items-center w-full">
              <ThemeProvider>
                  <Router>
                      <Routes>
                          <Route path={"/"} element={<MainPage/>}/>
                          <Route path={"/index"} element={<MainPage/>}/>
                          <Route path="/products" element={<Products products={products}/>}/>
                          <Route path="/products/:id" element={<ProductWrapper products={products}/>}/>
                      </Routes>
                  </Router>
              </ThemeProvider>
          </div>
      </>
  );
}

export default App;
