import './ShortInfo.css';
import facial_cleanser from "../../../assets/images/main-page/cleanser-for-site-1.jpg";
import hyalurone from "../../../assets/images/main-page/ha-serum-for-site-1.jpg";
import moisturiser from "../../../assets/images/main-page/moisturizer-for-site-1.jpg";
import {ReactComponent as Trees} from "../../../assets/images/main-page/re_trees.svg";
import {ReactComponent as Lamp} from "../../../assets/images/main-page/cowork_lamp.svg";
import {ReactComponent as Hand} from "../../../assets/images/main-page/8yo_hand.svg";

import left1 from "../../../assets/images/main-page/left.png"
import right1 from "../../../assets/images/main-page/right-1.png"
import right2 from "../../../assets/images/main-page/right-2.png"
import right3 from "../../../assets/images/main-page/right-3.png"
import right4 from "../../../assets/images/main-page/right-4.png"
import {NavLink} from "react-router-dom";

export const ShortInfo = () => {

    return (
        <>
            <div className="w-full flex justify-center items-center py-10">
                <div className="py-10 shortinfo-content w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:px-4">
                    <NavLink to={"/products/1"}>
                        <div className="w-full simple-highlight-image h-5 cursor-pointer" style={{background: `url(${facial_cleanser}) center center / cover no-repeat`}}></div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">Facial Cleanser</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">25$</p>
                    </NavLink>
                    <NavLink to={"/products/2"}>
                        <div className="w-full simple-highlight-image h-5 cursor-pointer"
                             style={{background: `url(${hyalurone}) center center / cover no-repeat`}}></div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">Hyaluronic Acid</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">24$</p>
                    </NavLink>
                    <NavLink to={"/products/3"}>
                        <div className="w-full simple-highlight-image h-5 cursor-pointer "
                             style={{background: `url(${moisturiser}) center center / cover no-repeat`}}></div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">Facial Moisturizer</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">24$</p>
                    </NavLink>
                </div>
            </div>
            <div className="w-full py-10">
                <div className="shortinfo-about grid grid-cols-1 md:grid-cols-2">
                    <div className="order-2 md:order-1 bg-gray-300 p-10" style={{backgroundColor: "#faf9f5"}}>
                        <h3 className="shortinfo-about-title p-10">About L’éternité</h3>
                        <p className="shortinfo-about-text pt-6 p-10">L’éternité is a pioneering company in the cosmetic care industry, renowned for creating the best forms of skincare products that cater to a diverse range of needs. Specializing in low-weight hyaluronic acid formulations, L’éternité's products are designed to penetrate deeply into the skin, providing superior hydration and rejuvenation. Their innovative approach combines cutting-edge technology with natural ingredients, ensuring that every product delivers visible results while maintaining the highest standards of safety and quality. L’éternité is committed to empowering individuals by enhancing their natural beauty, making skincare a luxurious and transformative experience for all.</p>
                    </div>
                    <div className="shortinfo-about-image order-1 md:order-2 bg-gray-500 p-4"></div>
                </div>
            </div>
            <div className="w-full py-10 flex flex-col items-center">
                <h3 className="shortinfo-products-header py-10 mt-10 text-center sm:text-center">We take pride in our products</h3>
                <div className="py-10 mt-10 mb-10 w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:px-4">
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Trees width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">Ingredients</p>
                        <p className="text-sm text-center">L’éternité's skincare line is crafted using natural, safe, high-quality ingredients to enhance and rejuvenate the skin.</p>
                    </div>
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Lamp width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">Design</p>
                        <p className="text-sm text-center">L’éternité's skincare products feature a sleek and user-friendly design, making them easy to incorporate into any beauty routine while ensuring an enjoyable and luxurious experience</p>
                    </div>
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Hand width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">Quality</p>
                        <p className="text-sm text-center">L’éternité's skincare products are meticulously crafted to deliver the highest quality and provide the best possible care for your skin. Each product is designed with cutting-edge formulations and premium ingredients to ensure effective hydration, rejuvenation, and overall skin health.</p>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center py-24 px-10" style={{backgroundColor: '#faf9f5'}}>
                <h3 className="text-center followus-header py-14">Follow us:</h3>
                <div className="pb-20 w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                    <div className="follow-column followus-photo border" style={{backgroundImage: `url(${left1})`}}></div>
                    <div className="follow-column grid gap-6 grid-cols-2">
                        <div className="followus-photo" style={{backgroundImage: `url(${right1})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right2})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right3})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right4})`}}></div>
                    </div>
                </div>
            </div>
        </>
    );
}