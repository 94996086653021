import { useState, useEffect } from 'react';

interface CartItem {
  id: number;
  image: string;
  title: string;
  price: number;
  quantity: number;
}

export const useCart = () => {
  const [cart, setCart] = useState<CartItem[]>([]);

  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      console.log('Loaded cart from localStorage:', JSON.parse(savedCart));
      setCart(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      console.log('Saving cart to localStorage:', cart);
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
      if (existingItem) {
        return prevCart.map(cartItem =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + item.quantity }
            : cartItem
        );
      } else {
        return [...prevCart, item];
      }
    });
  };

  const removeFromCart = (id: number) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(item => item.id !== id);
      if (updatedCart.length === 0) {
        localStorage.removeItem('cart');
      }
      return updatedCart;
    });
  };

  const updateCartQuantity = (id: number, quantity: number) => {
    setCart((prevCart) => {
      if (quantity <= 0) {
        const updatedCart = prevCart.filter(item => item.id !== id);
        if (updatedCart.length === 0) {
          localStorage.removeItem('cart');
        }
        return updatedCart;
      } else {
        return prevCart.map(cartItem =>
          cartItem.id === id ? { ...cartItem, quantity: quantity } : cartItem
        );
      }
    });
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };

  return { cart, addToCart, removeFromCart, clearCart, updateCartQuantity};
};