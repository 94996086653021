import {useCart} from "./useCart";
import './Cart.css'
import {useEffect} from "react";
import {RxCross1} from "react-icons/rx";

interface CartProps {
    stateToggle: () => void;
}

export const Cart = (props: CartProps) => {
    const {cart, removeFromCart, updateCartQuantity} = useCart();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div className="cart-overlay fixed inset-0 flex items-center justify-center">
                <RxCross1
                    onClick={props.stateToggle}
                    className="cursor-pointer absolute top-0 right-0 text-white m-4"
                    style={{fontSize: '30px'}}
                />
                <div className="cart-content relative w-full sm:w-full md:w-4/5 lg:w-2/4 xl:w-1/3 shadow-lg border rounded-xl bg-white p-10 max-h-screen overflow-y-auto">
                    <div className="border-b pt-4 pb-8">
                        <h2 style={{fontSize: '24px'}}>Your Cart:</h2>
                    </div>
                    <div className="flex flex-col items-center">
                        <ul className="w-full py-4 border-b">
                            {cart.map(item => (
                                <li key={item.id} className="w-full flex justify-between mb-4">
                                    <div className="flex justify-center items-center"
                                         style={{width: '90px', height: '90px'}}>
                                        {item.image && (
                                            <img src={item.image} className="w-full h-full" alt=""/>
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center"
                                         style={{width: '90px', height: '90px'}}>
                                        {item.title}
                                    </div>
                                    <div className="flex justify-center items-center"
                                         style={{width: '90px', height: '90px'}}>
                                        <input
                                            className="outline-0"
                                            style={{width: '45px'}}
                                            type="number"
                                            min={1}
                                            max={1000}
                                            value={item.quantity}
                                            onChange={(e) => updateCartQuantity(item.id, parseInt(e.target.value))}/>
                                    </div>
                                    <div className="flex justify-center items-center"
                                         style={{width: '90px', height: '90px'}}>
                                        ${item.price * item.quantity}
                                    </div>
                                    <div className="flex justify-center items-center"
                                         style={{width: '90px', height: '90px'}}>
                                        <button onClick={() => removeFromCart(item.id)}
                                                className="p-1 border rounded-full">
                                            <RxCross1 className="text-xs text-gray-500"/>
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex justify-end py-6 pr-4">
                        <span className="pr-2">Total sum:</span>
                        {cart.reduce((acc, item) => {
                            return acc + item.price * item.quantity;
                        }, 0)} $
                    </div>

                    <div className="form-container w-full">
                        <form method="post" className="flex flex-col items-center py-4">
                            <label className="w-full pt-4">
                                Your name
                                <input name="name" type="text"
                                       className="rounded-md w-full mt-2 py-3 text-xl border border-black"/>
                            </label>
                            <label className="w-full pt-4">
                                Your email
                                <input name="email" type="text"
                                       className="rounded-md w-full mt-2 py-3 text-xl border border-black"/>
                            </label>
                            <label className="w-full pt-4">
                                Telephone number
                                <input name="name" type="text"
                                       className="rounded-md w-full mt-2 py-3 text-xl border border-black"/>
                            </label>

                            <button type={"submit"} className="w-full mt-10 text-white bg-black py-3 px-4 rounded-xl">Buy</button>
                            <button className="w-full mt-5 border border-gray-800 py-3 px-4 rounded-xl hover:bg-gray-200" onClick={props.stateToggle}>Close</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}