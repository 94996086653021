interface Product {
    id: number;
    title: string;
    price: number;
    description: string;
}


interface ProductsProps {
    products: Product[];
}

export const Products = ({ products }: ProductsProps) => {
    return (
        <div>
            <h1>Products</h1>
            <ul>
                {products.map(product => (
                    <li key={product.id}>
                        {product.title} - ${product.price}
                    </li>
                ))}
            </ul>
        </div>
    );
}