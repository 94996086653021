import { useParams, useNavigate } from "react-router-dom";
import {useState} from "react";
import { RxCross1 } from "react-icons/rx";
import {CgArrowLongLeft} from "react-icons/cg";
import {BsBag} from "react-icons/bs";

import {useCart} from "../cart/useCart";
import {Cart} from "../cart/Cart"

interface ProductInterface {
    id: number;
    images: string[];
    title: string;
    price: number;
    description: string;
}


interface ProductWrapperProps {
    products: ProductInterface[];
}

interface ProductProps {
    id: number;
    product?: ProductInterface;
}

export const ProductWrapper = (props: ProductWrapperProps) => {
    const { id } = useParams<{ id: string }>();
    const productId = parseInt(id ?? '', 10);

    if (isNaN(productId)) {
        return <div>Invalid Product ID</div>;
    }

    const product = props.products.find(p => p.id === productId);

    if (!product) {
        return <div className="py-20 select-none" style={{fontSize: '60px'}}>Product not found</div>;
    }

    return <Product id={productId} product={product} />;
}

const Product = (props: ProductProps) => {
    const navigate = useNavigate();
    const { product } = props;
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    const {cart, addToCart} = useCart();
    const [cartOpened, setCartOpened] = useState<boolean>(false);
    const toggleCartOpened = () => setCartOpened(!cartOpened)

    const handleClick = () => {
        if (product?.id !== undefined && product?.title && product?.price !== undefined) {
            addToCart({
                id: product.id,
                image: product.images[0],
                title: product.title,
                price: product.price,
                quantity: 1,
            });
        } else {
            console.error("Product data is incomplete");
        }
    };

    const handleNextImage = () => {
        if (product && product.images) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
        }
    };

    const handlePreviousImage = () => {
        if (product && product.images) {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex === 0 ? product.images.length - 1 : prevIndex - 1)
            );
        }
    };

    const handleThumbnailClick = (index: number) => {
        setCurrentImageIndex(index);
    };

    return (
        <>
            {cartOpened && (
                    <>
                        <Cart stateToggle={toggleCartOpened}/>
                    </>
            )}
            <div className="product-page w-full flex justify-center py-20 relative">
                {cart.length > 0 && !cartOpened && (
                    <>
                        <button onClick={toggleCartOpened}
                                style={{zIndex: 100000}}
                            className="cart-button fixed top-0 right-0 mt-20 mr-6 p-6 border bg-white shadow-2xl rounded-full opacity-90 transition-all hover:shadow-md hover:translate-y-1">
                            <BsBag style={{fontSize: '36px'}}/>
                            <div
                                className="absolute flex justify-center items-center bottom-0 right-0 bg-black text-white p-2 rounded-full"
                                style={{width: '30px', height: '30px'}}>
                                {cart.length}
                            </div>
                        </button>
                    </>
                )}
                {!cartOpened && (
                    <>
                        <RxCross1 onClick={() => navigate('/')} className="cursor-pointer absolute mt-4 mr-4 top-0 right-0" style={{fontSize: '30px'}}/>
                        <div onClick={() => navigate("/")} className="absolute top-0 left-0 mt-4 ml-4 text-sm cursor-pointer flex flex-nowrap items-center">
                            <CgArrowLongLeft/>
                            All products
                        </div>
                    </>
                )}
                <div className="px-5 w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">

                    <div className="min-h-dvh">
                        <div className="relative h-96 flex justify-center items-center" style={{minHeight: '560px'}}>
                            <button
                                className="absolute left-0 bg-black bg-opacity-50 text-white px-2 py-1 cursor-pointer"
                                onClick={handlePreviousImage}
                            >
                                &lt;
                            </button>
                            <div
                                className="w-full h-full rounded-xl shortinfo-bg-image"
                                style={{
                                    backgroundImage: `url(${product?.images[currentImageIndex]})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center'
                                }}
                            ></div>
                            <button
                                className="absolute right-0 bg-black bg-opacity-50 text-white px-2 py-1 cursor-pointer"
                                onClick={handleNextImage}
                            >
                                &gt;
                            </button>
                        </div>
                        <div className="flex justify-start mt-4 space-x-2">
                            {product?.images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`w-20 h-20 cursor-pointer border ${index === currentImageIndex ? 'border-black' : 'border-transparent'}`}
                                    style={{
                                        backgroundImage: `url(${image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center'
                                    }}
                                    onClick={() => handleThumbnailClick(index)}
                                ></div>
                            ))}
                        </div>
                    </div>

                    <div className="min-h-dvh">
                        <h1 className="text-gray-500 py-4" style={{fontSize: "24px"}}>{product?.title}</h1>
                        <p className="font-bold py-3" style={{fontSize: '24px'}}>{product?.price}$</p>
                        <button onClick={handleClick}
                            className="w-full sm:w-full md:w-auto lg:w-auto xl:w-auto cursor-pointer my-5 mt-2 text-sm text-bold bg-black text-white px-7 py-3 rounded-3xl">Add
                            to cart
                        </button>
                        <p className="py-3" style={{fontSize: '14px'}}>{product?.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}